export default function Icon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="33" viewBox="0 0 34 33" fill="none">
            <g id="Group">
                <path
                    id="Path"
                    d="M30.8776 20.299C31.2604 18.9892 31.4655 17.6054 31.4655 16.1744C31.4655 7.95034 24.6919 1.28345 16.3362 1.28345C7.98052 1.28345 1.20691 7.95034 1.20691 16.1744C1.20691 24.3984 7.98052 31.0653 16.3362 31.0653C17.9641 31.0653 19.5319 30.8122 21.0013 30.3439"
                    stroke="black"
                    strokeWidth="1.72308"
                    strokeLinecap="round"
                />
                <path
                    id="Fill-1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M25.6466 27.603C26.5433 28.0714 27.8444 28.3117 29.138 28.3117C30.4315 28.3117 31.7326 28.0714 32.6293 27.603V28.3117C32.6293 28.7058 31.3038 29.4296 29.138 29.4296C26.9721 29.4296 25.6466 28.7058 25.6466 28.3117V27.603ZM32.6293 25.9723C32.6293 26.3663 31.3038 27.0902 29.138 27.0902C26.9721 27.0902 25.6466 26.3663 25.6466 25.9723C25.6466 25.5782 26.9721 24.8544 29.138 24.8544C31.3038 24.8544 32.6293 25.5782 32.6293 25.9723ZM25.6466 30.5475V29.8388C26.5433 30.3072 27.8444 30.5475 29.138 30.5475C30.4315 30.5475 31.7326 30.3072 32.6293 29.8388V30.5475C32.6293 30.9416 31.3038 31.6654 29.138 31.6654C26.9721 31.6654 25.6466 30.9416 25.6466 30.5475ZM33.7931 30.5475V25.8554C33.7931 24.3876 31.4516 23.6196 29.138 23.6196C26.8243 23.6196 24.4828 24.3876 24.4828 25.8554V30.5475C24.4828 32.0153 26.8243 32.7833 29.138 32.7833C31.4516 32.7833 33.7931 32.0153 33.7931 30.5475Z"
                    fill="black"
                />
                <path
                    id="Combined-Shape"
                    d="M9.35352 13.5525H15.4998M9.35352 20.5141H15.4998M18.3365 13.5525H24.4828M9.35352 17.1274H15.4998M18.3365 20.756V17.6002C18.3365 17.3391 18.5482 17.1274 18.8093 17.1274H24.4828"
                    stroke="#070707"
                    strokeWidth="1.72308"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
}
